.custom-row-height {
  height: 1rem; /* Adjust as needed */
}

.table-striped tbody tr {
  height: 45px; /* Set a fixed height for the table rows */
}

.table {
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
}

.table th, .table td {
  vertical-align: middle;
  white-space: pre-wrap;
}

.table th.time-column, .table td.time-column {
  width: 20%;
}

.table th.team-column, .table td.team-column {
  width: 40%;
}

.table th.room-column, .table td.room-column {
  width: 20%;
  max-width: 20%;
  min-width: 20%;
}

.table tbody tr {
  height: 80px; /* Adjust this value as needed */
}

.table th.description-column, .table td.description-column {
  width: 80%;
}

.kanit-regular {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.kanit-medium {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.kanit-bold {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.kanit-extrabold {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.venue-schedule-container .table {
  width: 100%;
  max-width: 550px;
  margin: auto;
}

.venue-schedule-container .time-column,
.venue-schedule-container .team-column,
.venue-schedule-container .room-column {
  vertical-align: middle;
  white-space: nowrap;
}

.venue-schedule-container .team-column,
.venue-schedule-container .room-column {
  text-align: left;
}

.venue-schedule-container .team-column div,
.venue-schedule-container .room-column div {
  line-height: 1.5;
}

.sponsor-logo {
  max-height: 60px; /* Set a maximum height */
  max-width: 240px; /* Set a maximum width */
  object-fit: contain; /* Ensures the logo is scaled proportionately */
}

