@font-face {  font-family: "Montserrat"; src: url(Montserrat-Regular.ttf)}
@font-face {  font-family: "Montserrat Bold"; src: url(Montserrat-Bold.ttf)}
@font-face {  font-family: "Montserrat SemiBold"; src: url(Montserrat-SemiBold.ttf)}
@font-face {  font-family: "Michroma"; src: url(Michroma.ttf)}
@font-face {  font-family: "Impact"; src: url(impact.ttf)}

::-webkit-scrollbar {
width: 0px;
height: 0px;
}


.luther-app-container  {
	background-color: #4f4f4f;
	font-family: "Montserrat";
}


	
.luther-midle_main{
	position: absolute;
	width: 98%;
	height: 76%;
	top: 240px;
	left: 20px;
	color: #FFFFFF;
	background-color: #214d72;

	border-radius: 20px;
}



.luther-midle{
	position: relative;
	width: 99%;
	height: 76%;
	top: 15px;
	color: #FFFFFF;
	padding: 10px;
}



.luther-title{
	position: absolute;
	width: 98%;
	top: 170px;
	left: 20px;
	font-size: 50px;
	color: #FFFFFF;
	font-family: "Montserrat Bold";
}


.luther-header_top {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 15%;
	background-color: #01447b;
	border-radius: 0px 0px 20px 20px;
}

.luther-header_txt_left {
	position: absolute;
	font-family: "Impact";
	font-size: 100px;
	color: #FFFFFF;
	top: 5px;
	left: 20px;
	padding: 10px;
	padding-right: 20px;
	width: 300px;
}
.luther-header_txt_left_bottom {
	position: absolute;
	font-family: "Montserrat";
	font-size: 21px;
	color: #FFFFFF;
	top: 90px;
	left: 10px;
	
	padding: 10px;
	padding-right: 20px;
}


.luther-header_right {
	position: absolute;
	font-family: Impact, 'Arial Narrow Bold', sans-serif;
	font-size: 70px;
	color: #FFFFFF;
	top: 10px;
	right: 10px;
	padding: 10px;
	padding-right: 20px;

}
.luther-header_right_bottom {
	position: absolute;
	font-family: "Montserrat";
	font-size: 28px;
	color: #FFFFFF;
	top: 90px;
	right: 10px;
	padding: 10px;
	padding-right: 20px;

}


.luther-bottom{
	position: absolute;
	bottom: 0px;
	width: 100%;
	left: 0px;
	height: 10%;
	background-color: #0218b9;
	border-radius: 20px 20px 0px 0px;

}



.luther-center{
	position: absolute;
	margin: auto;
	display: flex;
	color: #FFFFFF;
	font-family: "Montserrat Bold";
	font-size: 50px;
	top: 30px;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: auto;
}
.luther-center_small{
	position: absolute;
	margin: auto;
	display: flex;
	color: #FFFFFF;
	font-family: "Montserrat Bold";
	font-size: 30px;
	top: 90px;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: auto;
}	



.luther-width10{
	width: 10%;
}

.luther-width35{
	width: 35%;
}



div.luther-blueTable {
	border: 0px solid #1C6EA4;
	background-color: #214d72;
	width: 100%;
	text-align: center;
	border-collapse: collapse;
  }
  .divTable.luther-blueTable .divTableCell, .divTable.blueTable .divTableHead {
	border: 1px solid rgb(255, 255, 255, 0);
	padding: 3px 2px;
  }
  .divTable.luther-blueTable .divTableBody .divTableCell {
	font-size: 40px;
	height: 64px;
	font-family: "Montserrat SemiBold";
	padding-top: 20px;
	color: #FFFFFF;
  }
  .divTable.luther-blueTable .divTableRow:nth-child(even) {
	background: #0e3b62;
  }
  .divTable.luther-blueTable .divTableHeading {
	  border-bottom: 2px solid #FFFFFF;
  }
  .divTable.luther-blueTable .divTableHeading .divTableHead {
	font-family: "Montserrat Bold";
	font-size: 40px;
	font-weight: bold;
	color: #FFFFFF;
  }
  .luther-blueTable .tableFootStyle {
	font-size: 10px;
  }
  .luther-blueTable .tableFootStyle .links {
	text-align: right;
  }
  .luther-blueTable .tableFootStyle .links a{
	display: inline-block;
	background: #1C6EA4;
	color: #FFFFFF;
	padding: 2px 8px;
	border-radius: 5px;
  }
  .luther-blueTable.outerTableFooter {
	border-top: none;
  }
  .luther-blueTable.outerTableFooter .tableFootStyle {
	padding: 3px 5px; 
  }
  /* DivTable.com */
  .divTable{ display: table; }
  .divTableRow { display: table-row; }
  .divTableHeading { display: table-header-group;}
  .divTableCell, .divTableHead { display: table-cell;}
  .divTableHeading { display: table-header-group;}
  .divTableFoot { display: table-footer-group;}
  .divTableBody { display: table-row-group;}