/* src/App.css */
/* Uncomment when creating templates to create 1920 x 1080 templates */
/* html, body, #root {
    width: 1920px !important;
    height: 1080px !important;
    margin: 0;
    padding: 0;
    max-width: 1920px !important;
    max-height: 1080px !important;
  } */

/* Vertical Templates */
/* html, body, #root {
  width: 1080px;
  height: 1920px;
  margin: 0;
  padding: 0;
} */

.team-column {
    text-align: center;
}
