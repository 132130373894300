@font-face {  font-family: "Montserrat"; src: url(Montserrat-Regular.ttf)}
@font-face {  font-family: "Montserrat Bold"; src: url(Montserrat-Bold.ttf)}
@font-face {  font-family: "Montserrat SemiBold"; src: url(Montserrat-SemiBold.ttf)}
@font-face {  font-family: "Michroma"; src: url(Michroma.ttf)}
@font-face {  font-family: "Impact"; src: url(impact.ttf)}

	::-webkit-scrollbar {
	width: 0px;
	height: 0px;
	}
	



	.mck-app-container  {
		width: 1080px;
		height: 1920px;
		background-color: #4f4f4f;
		font-family: "Montserrat";
	}
	
	.ipsc-vert-header_top {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 10%;
		background-color: #01447b;
		border-radius: 0px 0px 0px 20px;
	}
	.ipsc-vert-logo_top{
		position: absolute;
		width: 300px;
		top: 230px;
		right: 40px;
		border-radius: 10px;
	}
	.ipsc-vert-header_txt_left {
		position: absolute;
		font-family: "Impact";
		font-size: 100px;
		color: #FFFFFF;
		top: 10px;
		left: 20px;
		padding: 10px;
		padding-right: 20px;
		width: 300px;
	}

	.ipsc-vert-center{
		position: absolute;
		margin: auto;
		display: flex;
		color: #FFFFFF;
		font-family: "Montserrat Bold";
		font-size: 50px;
		top: 35px;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: auto;
	}
	.ipsc-vert-center_small{
		position: absolute;
		margin: auto;
		display: flex;
		color: #FFFFFF;
		font-family: "Montserrat Bold";
		font-size: 40px;
		top: 105px;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: auto;
	}	
		
		
	.mck-midle_main{
		position: absolute;
		width: 97%;
		height: 1420px;
		top: 430px;
		left: 15px;
		color: #FFFFFF;
		background-color: #214d72;

		border-radius: 20px;
	}
	


	.mck-midle{
		position: relative;
		width: 99%;
		top: 35px;
		color: #FFFFFF;
		padding: 10px;
	}
	


	.mck-title1{
		position: absolute;
		width: 98%;
		top: 280px;
		left: 20px;
		font-size: 50px;
		color: #FFFFFF;
		font-family: "Montserrat Bold";
	}

	.mck-ct-logo_top {
		position: absolute;
		width: 190px !important;
		top: 245px !important;
		right: 40px !important;
		border-radius: 10px;
	}

	.mck-logo_top-toyota{
		position: absolute;
		width: 350px !important;
		top: 235px !important;
		right: 40px !important;
		border-radius: 10px;
	}

	.mck-header_top {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 10%;
		background-color: #01447b;
		border-radius: 0px 0px 0px 20px;
	}
	.mck-logo_top{
		position: absolute;
		width: 350px !important;
		top: 270px !important;
		right: 40px !important;
		border-radius: 10px;
	}
	.mck-header_txt_left {
		position: absolute;
		font-family: "Impact";
		font-size: 100px;
		color: #FFFFFF;
		top: 10px;
		left: 60px;
		padding: 10px;
		padding-right: 20px;
	}
	.mck-header_txt_left_bottom {
		position: absolute;
		font-family: "Montserrat";
		font-size: 30px;
		color: #FFFFFF;
		top: 120px;
		left: 60px;
		
		padding: 10px;
		padding-right: 20px;
	}

	
	.mck-header_right {
		position: absolute;
		font-family: Impact, 'Arial Narrow Bold', sans-serif;
		font-size: 80px;
		color: #FFFFFF;
		top: 10px;
		right: 10px;
		padding: 10px;
		padding-right: 20px;

	}
	.mck-header_right_bottom {
		position: absolute;
		font-family: "Montserrat";
		font-size: 30px;
		color: #FFFFFF;
		top: 110px;
		right: 10px;
		padding: 10px;
		padding-right: 20px;

	}
	
	
	.mck-bottom{
		position: absolute;
		bottom: 0px;
		width: 100%;
		left: 0px;
		height: 10%;
		background-color: #0218b9;
		border-radius: 20px 20px 0px 0px;

	}



	.mck-center{
		position: absolute;
		margin: auto;
		display: flex;
		color: #FFFFFF;
		font-family: "Montserrat Bold";
		font-size: 36px;
		top: 70px;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: auto;
	}
	.mck-center_small{
		position: absolute;
		margin: auto;
		display: flex;
		color: #FFFFFF;
		font-family: "Montserrat Bold";
		font-size: 26px;
		top: 110px;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: auto;
	}	
	


	.mck-width10{
		width: 10%;
	}

	.mck-width80{
		width: 80%;
	}



	div.mck-blueTable {

		background-color: #214d72;
		width: 100%;
		text-align: left;
		border-collapse: collapse;
	  }
	  .divTable.mck-blueTable .divTableCell, .divTable.blueTable .divTableHead {
		border: 1px solid rgb(255, 255, 255, 0);
		padding: 3px 2px;
	  }
	  .divTable.mck-blueTable .divTableBody .divTableCell {
		font-size: 50px;
		height: 120px;
		font-family: "Montserrat SemiBold";
		padding-top: 20px;
		color: #FFFFFF;
		line-height: 60px;
	  }
	  .divTable.mck-blueTable .divTableRow:nth-child(even) {
		background: #0e3b62;
	  }
	  .divTable.mck-blueTable .divTableHeading {
		  border-bottom: 2px solid #FFFFFF;
	  }
	  .divTable.mck-blueTable .divTableHeading .divTableHead {
		font-family: "Montserrat Bold";
		font-size: 50px;
		font-weight: bold;
		color: #FFFFFF;
	  }
	  .mck-blueTable .tableFootStyle {
		font-size: 10px;
	  }
	  .mck-blueTable .tableFootStyle .links {
		   text-align: right;
	  }
	  .mck-blueTable .tableFootStyle .links a{
		display: inline-block;
		background: #1C6EA4;
		color: #FFFFFF;
		padding: 2px 8px;
		border-radius: 5px;
	  }
	  .mck-blueTable.outerTableFooter {
		border-top: none;
	  }
	  .mck-blueTable.outerTableFooter .tableFootStyle {
		padding: 3px 5px; 
	  }
	  /* DivTable.com */
	  .divTable{ display: table; }
	  .divTableRow { display: table-row; }
	  .divTableHeading { display: table-header-group;}
	  .divTableCell, .divTableHead { display: table-cell;}
	  .divTableHeading { display: table-header-group;}
	  .divTableFoot { display: table-footer-group;}
	  .divTableBody { display: table-row-group;}