.kanit-regular {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.kanit-medium {
    font-family: "Kanit", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.kanit-bold {
    font-family: "Kanit", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.kanit-extrabold {
    font-family: "Kanit", sans-serif;
    font-weight: 800;
    font-style: normal;
}

/* Shared Header Style */
.header-ipsc {
    display: flex;
    align-items: center; /* Vertically centers content */
    justify-content: space-between; /* Ensures consistent spacing */
    height: 110px; /* Consistent height for all headers */
    padding: 0 20px; /* Adds padding for spacing */
}

/* Title centered */
.header-ipsc-title {
    flex: 1; /* Allows title to take up equal space */
    text-align: center; /* Centers the title */
}

/* Date and Time aligned to the right */
.header-ipsc-date {
    justify-content: flex-end; /* Aligns content to the right */
}

/* Ensures text doesn't overflow */
.navbar-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
