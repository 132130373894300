/* Add this to your CSS file */
.scrolling-text {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
  }
  
.scrolling-text h1 {
  display: inline-block;
  padding-left: 100%;
  animation: scrolling-text 120s linear infinite;
}

@keyframes scrolling-text {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.kanit-regular {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.kanit-medium {
    font-family: "Kanit", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.kanit-bold {
    font-family: "Kanit", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.kanit-extrabold {
    font-family: "Kanit", sans-serif;
    font-weight: 800;
    font-style: normal;
}
