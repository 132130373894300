/* Login.css */
.login-container {
    height: 100vh;
    background: #f8f9fa;
  }
  
  .login-card {
    width: 100%;
    max-width: 400px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-title {
    font-size: 1.5rem;
    color: #007bff;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    border-color: #004085;
  }
  