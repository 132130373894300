	@font-face {  font-family: "Montserrat"; src: url(Montserrat-Regular.ttf)}
	@font-face {  font-family: "Montserrat Bold"; src: url(Montserrat-Bold.ttf)}
	@font-face {  font-family: "Montserrat SemiBold"; src: url(Montserrat-SemiBold.ttf)}
	@font-face {  font-family: "Michroma"; src: url(Michroma.ttf)}
	@font-face {  font-family: "Impact"; src: url(impact.ttf)}

	::-webkit-scrollbar {
	width: 0px;
	height: 0px;
	}
	



	.ipsc-full-app-container  {
		background-color: #4f4f4f;
		font-family: "Montserrat";
	}

	.ipsc-full-midle{
		position: absolute;
		width: 99%;
		height: 73.5%;
		top: 175px;
	}
	

	.ipsc-full-split_left {
		position: absolute;
		height: 100%;
		width: 30%;
		top: 0px;
		
		z-index: 1;
		overflow-x: hidden;

	}

	.ipsc-full-split_right {
		position: absolute;
		height: 100%;
		width: 69%;
		top: 0px;
		
		z-index: 1;
		overflow-x: hidden;

	}


	.ipsc-full-left {
		left: 0;

	}
	  
	.ipsc-full-right {
		right: 0;
	}


	.ipsc-full-header_top {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 15%;
		background-color: #01447b;
		border-radius: 0px 0px 20px 20px;
	}
	
	.ipsc-full-header_txt_left {
		position: absolute;
		font-family: "Impact";
		font-size: 100px;
		color: #FFFFFF;
		top: 10px;
		left: 20px;
		padding: 10px;
		padding-right: 20px;
		width: 300px;
	}
	.ipsc-full-header_txt_left_bottom {
		position: absolute;
		font-family: "Montserrat";
		font-size: 21px;
		color: #FFFFFF;
		top: 90px;
		left: 10px;
		
		padding: 10px;
		padding-right: 20px;
	}

	
	.ipsc-full-header_right {
		position: absolute;
		font-family: Impact, 'Arial Narrow Bold', sans-serif;
		font-size: 80px;
		color: #FFFFFF;
		top: 5px;
		right: 10px;
		padding: 10px;
		padding-right: 20px;

	}
	.ipsc-full-header_right_bottom {
		position: absolute;
		font-family: "Montserrat";
		font-size: 30px;
		color: #FFFFFF;
		top: 90px;
		right: 10px;
		padding: 10px;
		padding-right: 20px;

	}
	
	
	.ipsc-full-bottom{
		position: absolute;
		bottom: 0px;
		width: 100%;
		left: 0px;
		height: 10%;
		background-color: #01447b;
		border-radius: 20px 20px 0px 0px;

	}



	.ipsc-full-center{
		position: absolute;
		margin: auto;
		display: flex;
		color: #FFFFFF;
		font-family: "Montserrat Bold";
		font-size: 50px;
		top: 30px;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: auto;
	}
	.ipsc-full-center_small{
		position: absolute;
		margin: auto;
		display: flex;
		color: #FFFFFF;
		font-family: "Montserrat Bold";
		font-size: 30px;
		top: 90px;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: auto;
	}	
	
	.ipsc-full-center_ticker{
		position: absolute;
		margin: auto;
		display: flex;
		color: #FFFFFF;
		font-family: "Montserrat Bold";
		font-size: 30px;
		top: 30px;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: auto;
	}

	.ipsc-full-booking{
		position: relative;
		width: 98%;
		height: 23.2%;
		background-color: #FFFFFF;
		border-radius: 20px;
		margin: 8px;
	}

	.ipsc-full-booking_txt_title{
		position: relative;
		font-family: "Montserrat Bold";
		padding: 10px;
		padding-left: 20px;
		color: black;
		font-size: 26px;
		top: 10px;
	}

	#bokking_11{
		position: relative;
		font-family: "Montserrat";
		padding: 10px;
		text-align: center;
		color: black;
		background-color: #d5d4d4;
		font-size: 24px;
		top: 30px;
	}
	#bokking_12{
		position: relative;
		font-family: "Montserrat";
		padding: 10px;
		text-align: center;
		color: black;
		font-size: 24px;
		top: 25px;
	}


	#bokking_21{
		position: relative;
		font-family: "Montserrat";
		padding: 10px;
		text-align: center;
		color: black;
		background-color: #d5d4d4;
		font-size: 24px;
		top: 30px;
	}
	#bokking_22{
		position: relative;
		font-family: "Montserrat";
		padding: 10px;
		text-align: center;
		color: black;
		font-size: 24px;
		top: 25px;
	}


	#bokking_31{
		position: relative;
		font-family: "Montserrat";
		padding: 10px;
		text-align: center;
		color: black;
		background-color: #d5d4d4;
		font-size: 24px;
		top: 30px;
	}
	#bokking_32{
		position: relative;
		font-family: "Montserrat";
		padding: 10px;
		text-align: center;
		color: black;
		font-size: 24px;
		top: 25px;
	}

	#bokking_41{
		position: relative;
		font-family: "Montserrat";
		padding: 10px;
		text-align: center;
		color: black;
		background-color: #d5d4d4;
		font-size: 24px;
		top: 30px;
	}
	#bokking_42{
		position: relative;
		font-family: "Montserrat";
		padding: 10px;
		text-align: center;
		color: black;
		font-size: 24px;
		top: 25px;
	}


	.ipsc-full-booking_txt_1{
		position: relative;
		font-family: "Montserrat";
		padding: 10px;
		text-align: center;
		color: black;
		background-color: #d5d4d4;
		font-size: 24px;
		top: 30px;
	}


	.ipsc-full-booking_txt_2{
		position: relative;
		font-family: "Montserrat";
		padding: 10px;
		text-align: center;
		color: black;
		font-size: 24px;
		top: 25px;

	}




	.ipsc-full-block_event{
		position: relative;
		width: 98%;
		height: 30.8%;
		margin: 10px;
	}

	.ipsc-full-block_space{
		width: 98%;
		height: 2px;
	}


	.ipsc-full-block_schedule_right{
		position: absolute;
		display: table;
		width: 47.5%;
		height: 98%;
		right: 0px;
		background-color:  #214d72;
		border-radius: 20px;
		padding: 6px;
	}
	.ipsc-full-block_schedule_left{
		position: absolute;
		display: table;
		width: 47.5%;
		height: 98%;
		
		background-color: #214d72;
		border-radius: 20px;
		padding: 6px;
	}

	.ipsc-full-block_schedule_txt_title{
		position: relative;
		font-family: "Montserrat Bold";
		padding: 10px;
		top:10px;
		padding-left: 5px;
		color: white;
		height: 50px;
		font-size: 30px;

	}

	.ipsc-full-block_schedule_txt_1{
		position: relative;
		font-family: "Montserrat SemiBold";
		padding: 6px;
		width: 98%;
		overflow: hidden;
		margin-bottom: 1rem;
		
		color: white;
		background-color: #0e3b62;
		font-size: 30px;
		top: 20px;
	}

	.ipsc-full-block_schedule_txt_2{
		position: relative;
		font-family: "Montserrat SemiBold";
		padding: 6px;

		color: white;
		font-size: 30px;
		top: 20px;
		margin-bottom: 1rem;
		margin-left: -0.5rem;
	}


	.ipsc-full-is_right_1{
		position: relative;
		float: right;
		color: #FFFFFF;
		font-family: "Montserrat";
	}

	.ipsc-full-is_right_2{
		position: relative;
		float: right;
		color: #FFFFFF;
		font-family: "Montserrat";
	}


	.ipsc-full-float_right{
		text-align: right;
	}
	.ipsc-full-float_left{
		text-align: left;
	}