.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  text-align: center;
  padding: 20px;
}

.not-found-container h1 {
  font-size: 120px;
  margin: 0;
  color: #e74c3c;
}

.not-found-container h2 {
  font-size: 32px;
  margin: 10px 0;
  color: #2c3e50;
}

.not-found-container p {
  font-size: 18px;
  color: #7f8c8d;
  margin: 20px 0;
}

.home-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.home-button:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .not-found-container h1 {
    font-size: 80px;
  }
  
  .not-found-container h2 {
    font-size: 24px;
  }
  
  .not-found-container p {
    font-size: 16px;
  }
} 