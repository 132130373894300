.table {
    table-layout: fixed;
    width: 100%;
  }
  
  .table th, .table td {
    vertical-align: middle;
    white-space: pre-wrap;
  }
  
  .table th.time-column, .table td.time-column {
    width: 18%;
  }
  
  .table th.description-column, .table td.description-column {
    width: 82%;
  }
  
  .table tbody tr {
    height: 30px;
  }
  
  .kanit-regular {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.kanit-medium {
    font-family: "Kanit", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.kanit-bold {
    font-family: "Kanit", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.kanit-extrabold {
    font-family: "Kanit", sans-serif;
    font-weight: 800;
    font-style: normal;
}
